/* eslint-disable @typescript-eslint/naming-convention */
import { AnimatePresence, motion, MotionProps } from 'framer-motion'
import React, { useEffect, useMemo, useState } from 'react'

import { getOneElastic } from '@hmn/rtl-web-core/hooks'
import { useLiveData } from '@hmn/rtl-web-core/hooks/data/useLiveData'

import { ClockNetIcon, PinNetIcon } from '../Icon'
import LiveWidgetStyled from './LiveWidget.net.style'

interface InitialLiveData {
    live_location: string
    live_coverage_start_time: string
    live_coverage_end_time: string
    live_competition: string
    live_label_active: boolean
    live_score: string
    live_hometeam: string
    live_guestteam: string
    live_homescorers: string
    live_guestscorers: string
}

interface LiveWidgetProps {
    initialData: InitialLiveData
    articleId: string
}

const parseDateTime = (dateTimeStr: string) => {
    const [datePart, timeWithOffset] = dateTimeStr.split('T')
    const [year, month, day] = datePart.split('-')
    const [timePart] = timeWithOffset.split(/[+-]/)
    const [hours, minutes] = timePart.split(':')
    return { year, month, day, hours, minutes }
}
// format one of time string
const formatDateTime = ({ day, month, year, hours, minutes }) => `${day}. ${month}. ${year} u ${hours}:${minutes}h`

const formatTimeRange = (startTimeStr?: string, endTimeStr?: string) => {
    if (!startTimeStr && !endTimeStr) {
        return '' // If both are missing
    }

    const start = (startTimeStr && parseDateTime(startTimeStr)) || false
    const end = (endTimeStr && parseDateTime(endTimeStr)) || false

    // if one of dates not set
    if (!!start && !end) {
        return formatDateTime(start)
    }
    if (!start && !!end) {
        return formatDateTime(end)
    }
    if (!start || !end) {
        // If both are missing
        return ''
    }

    // if the dates are the same
    if (start.day === end.day && start.month === end.month && start.year === end.year) {
        return `
        ${start.day}. ${start.month}. ${start.year} od ${start.hours}:${start.minutes}h - ${end.hours}:${end.minutes}h
        `
    }

    // default value
    return `${formatDateTime(start)} - ${formatDateTime(end)}`
}

const ATTRIBS_QUERY = [
    'live_location',
    'live_coverage_start_time',
    'live_coverage_end_time',
    'live_competition',
    'live_label_active',
    'live_score',
    'live_hometeam',
    'live_guestteam',
    'live_homescorers',
    'live_guestscorers'
]
    .map(k => `a=${k}`)
    .join('&')

const scoreAnimation: MotionProps = {
    initial: { transform: 'scaleY(0)', transformOrigin: 'top' },
    animate: { transform: 'scaleY(1)' },
    exit: { transform: 'scaleY(0)', transformOrigin: 'bottom' },
    transition: { duration: 0.3, transition: { ease: 'easeInOut' } }
}

const playerAnimation: MotionProps = {
    initial: { transform: 'translateX(20px)' },
    animate: { transform: 'translateX(0)' },
    exit: { transform: 'translateX(20px)' },
    transition: { duration: 0.3, transition: { ease: 'easeOut' } }
}

const TeamScore = ({ score }: { score: string }) => (
    <div className="live-widget__result-number">
        <AnimatePresence initial={false}>
            <motion.div key={score} {...scoreAnimation}>
                {score}
            </motion.div>
        </AnimatePresence>
    </div>
)

const TeamScoreres = ({ scorers }: { scorers: string[] }) => (
    <div className="live-widget__scorers">
        <AnimatePresence>
            {scorers.map(scorer => (
                <motion.p key={scorer} className="live-widget__scorer" {...playerAnimation}>
                    {scorer}
                </motion.p>
            ))}
        </AnimatePresence>
    </div>
)

const ScoreGrid = ({
    homeTeam,
    guestTeam,
    homeScore,
    guestScore,
    homeScorers,
    guestScorers
}: {
    homeScore: string
    guestScore: string
    homeScorers: string[]
    guestScorers: string[]
    homeTeam: string
    guestTeam: string
}) => {
    const hasResult = homeScore && guestScore

    return (
        <div className="live-widget__grid">
            {hasResult && (
                <>
                    <TeamScore score={homeScore} />
                    <TeamScore score={guestScore} />
                </>
            )}

            <div className="live-widget__team-name">{homeTeam}</div>
            <div className="live-widget__team-name">{guestTeam}</div>

            <TeamScoreres scorers={homeScorers} />
            <TeamScoreres scorers={guestScorers} />
        </div>
    )
}

const LiveWidget = ({ initialData, articleId }: LiveWidgetProps) => {
    const [isEnabled, setIsEnabled] = useState(initialData.live_label_active)
    const liveData = useLiveData({
        endpoint: `entity-extended-attribs/${articleId}?preview=true&${ATTRIBS_QUERY}`,
        ssrData: initialData,
        enabled: isEnabled,
        refreshTimeout: 15000,
        fetcher: async <DataType = any,>(url: string): Promise<DataType> => {
            const data = await getOneElastic(url)
            return data.data
        }
    })
    const {
        live_location: liveLocation,
        live_coverage_start_time: liveCoverageStartTime,
        live_coverage_end_time: liveCoverageEndTime,
        live_competition: liveCompetition,
        live_label_active: liveLabelActive,
        live_score: liveScore,
        live_hometeam: liveHometeam,
        live_guestteam: liveGuestteam,
        live_homescorers: liveHomeScorers,
        live_guestscorers: liveGuestScorers
    } = liveData || initialData

    useEffect(() => {
        setIsEnabled(!!liveLabelActive)
    }, [liveLabelActive])

    const { homeTeamScore, guestTeamScore, homeScorers, guestScorers } = useMemo(() => {
        const split = liveScore?.split(':')
        return {
            homeTeamScore: liveScore && split[0],
            guestTeamScore: liveScore && split[1],
            homeScorers: liveHomeScorers?.split(';') || [],
            guestScorers: liveGuestScorers?.split(';') || []
        }
    }, [liveScore, liveHomeScorers, liveGuestScorers])
    return (
        <LiveWidgetStyled>
            {liveLabelActive && (
                <p className="live-widget__live-label">
                    <span className="dot" /> UŽIVO
                </p>
            )}

            <div className="live-widget__header">
                {liveCompetition && <div className="live-widget__header-title">{liveCompetition}</div>}
                <div className="live-widget__header-details">
                    {liveLocation && (
                        <span>
                            <PinNetIcon width="10" height="12" viewBox="0 0 12 14" />
                            {liveLocation}
                        </span>
                    )}

                    {(liveCoverageStartTime || liveCoverageEndTime) && (
                        <span>
                            <ClockNetIcon width="12" height="12" viewBox="0 0 12 12" />
                            {formatTimeRange(liveCoverageStartTime, liveCoverageEndTime)}
                        </span>
                    )}
                </div>
            </div>
            <ScoreGrid
                homeTeam={liveHometeam}
                guestTeam={liveGuestteam}
                homeScore={homeTeamScore}
                guestScore={guestTeamScore}
                homeScorers={homeScorers}
                guestScorers={guestScorers}
            />
        </LiveWidgetStyled>
    )
}

export default LiveWidget
