/* eslint-disable @typescript-eslint/naming-convention */
import { createStyledComponent } from '@hmn/rtl-web-core/helpers/components/createStyledComponent'

import { toRem } from '../../helpers/theme'

const style = ({ theme }: any) => {
    const fwColor = theme.colors.decorationGrey
    const bgColor = theme.colors.sport

    return {
        position: 'relative',
        backgroundColor: theme.colors.light,
        padding: '30px 20px',
        marginBottom: '30px',
        textAlign: 'center',
        width: '100%',

        '@media(min-width: 768px)': {
            marginBottom: '40px'
        },

        // '&::before': {
        //     position: 'absolute',
        //     content: '""',
        //     top: 0,
        //     left: 0,
        //     right: 0,
        //     height: '10px',
        //     background: `repeating-linear-gradient(45deg, ${fwColor}, ${fwColor} 9px, ${bgColor} 9px, ${bgColor} 16px)`
        // },

        '&::after': {
            position: 'absolute',
            content: '""',
            right: 0,
            left: 0,
            bottom: 0,
            height: '10px',
            background: `repeating-linear-gradient(90deg, ${bgColor}, ${bgColor} 9px, ${fwColor} 9px, ${fwColor} 16px)`
        },

        '.live-widget__live-label': {
            position: 'absolute',
            top: '10px',
            right: '20px',
            fontSize: toRem(12),
            fontWeight: 'bold',
            color: theme.colors.netRed,

            '@media(min-width: 768px)': {
                fontSize: toRem(15)
            },

            '.dot': {
                display: 'inline-block',
                width: '4px',
                height: '4px',
                backgroundColor: theme.colors.netRed,
                borderRadius: '100%',
                margin: '0 5px 2px -3px',

                animation: 'pulse 1.3s infinite',
                '@keyframes pulse': {
                    '0%': { opacity: 1 },
                    '50%': { opacity: 0.1 },
                    '100%': { opacity: 1 }
                },

                '@media(min-width: 768px)': {
                    width: '6px',
                    height: '6px'
                }
            }
        },

        '.live-widget__header': {
            marginBottom: '20px'
        },

        '.live-widget__header-title': {
            padding: '5px 10px',
            fontFamily: 'proxima-nova,ArialToProximaNova,sans-serif',
            fontSize: toRem(20),
            fontWeight: 700,
            color: theme.colors.netGrey,
            lineHeight: 1.1,

            '@media(min-width: 768px)': {
                fontSize: toRem(20)
            }
        },

        '.live-widget__header-details': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '2px',
            fontSize: toRem(12),
            lineHeight: 1.3,
            fontWeight: 500,
            color: theme.colors.netGrey,

            '@media(min-width: 768px)': {
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '10px',
                fontSize: toRem(14)
            },

            svg: {
                position: 'relative',
                top: '-1px',
                width: '13px',
                height: '13px',
                marginRight: '2px',
                fill: 'none',
                strokeWidth: '1px'
            }
        },

        '.live-widget__grid': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '0 38px',

            '@media(min-width: 768px)': {
                gap: '0 68px'
            },

            '& > div': {
                flex: 'calc(50% - 19px) 0 0',
                textAlign: 'left',

                '@media(min-width: 768px)': {
                    flex: 'calc(50% - 34px) 0 0'
                },

                '&:nth-child(odd)': {
                    textAlign: 'right'
                }
            }
        },

        '.live-widget__team-name': {
            fontSize: toRem(18),
            fontWeight: 800,
            color: theme.colors.primary,
            lineHeight: 1,

            '@media(min-width: 768px)': {
                fontSize: toRem(24)
            }
        },

        '.live-widget__result-number': {
            position: 'relative',
            fontSize: toRem(48),
            fontWeight: 900,
            color: theme.colors.sport,
            lineHeight: 1,
            height: '48px',
            marginBottom: '10px',

            '@media(min-width: 768px)': {
                fontSize: toRem(68),
                height: '68px'
            },

            '&>div': {
                position: 'absolute',
                top: 0
            },

            '&:nth-child(1)>div': {
                right: 0
            },

            '&:nth-child(1)::after': {
                content: '":"',
                position: 'absolute',
                top: 0,
                right: '-26px',

                '@media(min-width: 768px)': {
                    right: '-45px'
                }
            }
        },

        '.live-widget__scorers': {
            marginTop: '10px',

            p: {
                marginTop: '5px',
                fontSize: toRem(12),
                lineHeight: 1.3,
                color: theme.colors.netGrey,
                wordBreak: 'break-word',

                '@media(min-width: 768px)': {
                    fontSize: toRem(14)
                }
            }
        }
    }
}

export default createStyledComponent({
    tag: 'div',
    styles: style
})
