import { useCallback, useEffect, useRef, useState } from 'react'

const ONE_SECOND = 1000
const REFRESH_TIMEOUT = 30 * ONE_SECOND
const fetchData = async <DataType = unknown>(url: string) => {
    const response = await fetch(url)
    return response.json() as Promise<DataType>
}
export const useLiveData = <DataType = unknown>({
    endpoint,
    ssrData,
    enabled = true,
    refresh = true,
    refreshTimeout = REFRESH_TIMEOUT,
    fetcher = fetchData
}: {
    endpoint: string
    ssrData?: DataType
    enabled?: boolean
    refresh?: boolean
    refreshTimeout?: number
    fetcher?: typeof fetchData
}) => {
    const [loadedResults, setLoadedResults] = useState<DataType | undefined>(ssrData)

    const timeoutRef = useRef<number>()
    const fetchDataInternal = useCallback(
        (retry = 0) => {
            if (timeoutRef.current) {
                window.clearTimeout(timeoutRef.current)
            }
            if (retry > 5) {
                return
            }
            fetcher<DataType>(endpoint)
                .then(loadedData => {
                    setLoadedResults(loadedData)
                    if (refresh) timeoutRef.current = window.setTimeout(fetchDataInternal, refreshTimeout)
                })
                .catch(() => {
                    fetchDataInternal(retry + 1)
                })
        },
        [endpoint, refresh, refreshTimeout]
    )

    const hasFiredInitialFetch = useRef(!!ssrData)

    useEffect(() => {
        if (!enabled)
            return () => {
                window.clearTimeout(timeoutRef.current)
            }
        if (!hasFiredInitialFetch.current) {
            hasFiredInitialFetch.current = true
            fetchDataInternal()
        } else {
            if (refresh) timeoutRef.current = window.setTimeout(fetchDataInternal, REFRESH_TIMEOUT)
        }
        return () => {
            window.clearTimeout(timeoutRef.current)
        }
    }, [fetchDataInternal, enabled, refresh])

    return loadedResults
}
