import { useCallback, useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import { PropTypes } from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary'
import { Seo } from '@hmn/rtl-web-core/components/Seo/Seo.component'
import { useClickedImageIndexDispatch, useGalleryIdDispatch } from '@hmn/rtl-web-core/context/articleModalGallery'

import { processAuthors } from '../../../../helpers/utils/processAuthors'
import { HtmlContent } from '../../../HtmlContent'
import { Image, imageRatioVariants } from '../../../Image'
import { Meta } from '../../../Meta'
import { Title } from '../../../Title'
import { Inpicture } from '../Inpicture/Inpicture.component'
import styles from './GalleryHead.net.style'

const HeadStyled = styled.div(props => ({ ...styles(props) }))

function GalleryHead({
    image,
    imageUpdatedAt,
    gallery,
    title,
    subtitle,
    lead,
    author,
    authors = [],
    showAuthorInitials,
    date,
    photoAuthor,
    categoryColor,
    columnist,
    authorSlug,
    inPictureAdConfig,
    ...rest
}) {
    const setClickedImageIndex = useClickedImageIndexDispatch()
    const setGalleryId = useGalleryIdDispatch()

    const openGallery = useCallback(
        index => {
            setClickedImageIndex(index)
            setGalleryId(gallery.id)
        },
        [setClickedImageIndex, setGalleryId, gallery.id]
    )

    const uid = useUIDSeed()

    const thumbImages = useMemo(() => {
        const galleryImages = gallery?.extended_attributes?.gallery_images || []
        return galleryImages.slice(0, 9)
    }, [gallery])

    return (
        <HeadStyled hasImage={image} {...rest}>
            <Seo item={gallery} images={gallery?.extended_attributes?.gallery_images} minorItem />
            <Title
                className="galleryHead__title"
                id="article_head_title"
                title={title}
                subtitle={subtitle}
                categoryColor={categoryColor}
            />

            <div className="galleryHead_inpicture_wrap">
                <Image
                    className="galleryHead__image"
                    classNameProgressive="galleryHead__imageProgressive"
                    id="article_head_image"
                    image={image}
                    updatedAt={imageUpdatedAt}
                    variation={imageRatioVariants.CUSTOM_WIDE_SCREEN}
                    alt={image?.title}
                    width="705"
                    height="400"
                    preload
                    lazyLoad={false}
                    useLargestInstance
                    isHeadImage
                    onClick={() => openGallery(0)}
                    sizesSm={100}
                    sizesMd={66}
                />
                <Inpicture key={image?.id} inPictureAdConfig={inPictureAdConfig} />
            </div>

            <ul className="galleryThumbs">
                {thumbImages?.map((thumbImage, index) => (
                    <li key={uid(image.id + index)} className="galleryThumbs__item">
                        <Image
                            className="galleryThumbs__img"
                            image={thumbImage}
                            variation={imageRatioVariants.CUSTOM_SQUARE} // CUSTOM_SQUARE, CUSTOM_WIDE_SCREEN
                            alt={title}
                            width={80}
                            height={80}
                            sizesSm="100px"
                            sizesMd="100px"
                            maxSrcsetWidth={200}
                            minSrcsetWidth={0}
                            preload
                            lazyLoad={false}
                            onClick={() => openGallery(index + 1)}
                        />
                    </li>
                ))}
            </ul>

            <HtmlContent data={lead} className="galleryHead__lead" />

            {/* if there is more than one author, show them instead widget */}
            {columnist && <div className="articleHead_columnist">{columnist}</div>}

            <Meta
                className="galleryHead__meta"
                id="article_head_meta"
                authorSlug={(!columnist && authorSlug) || undefined}
                author={(!columnist && author) || undefined}
                authors={!columnist && authors && processAuthors(authors)}
                showAuthorInitials={showAuthorInitials}
                photoAuthor={photoAuthor}
                date={date}
            />
        </HeadStyled>
    )
}

GalleryHead.propTypes = {
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    gallery: PropTypes.shape({
        id: PropTypes.string,
        extended_attributes: PropTypes.shape({
            gallery_images: PropTypes.arrayOf(PropTypes.string)
        })
    }),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    lead: PropTypes.string,
    author: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    authors: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired
        }),
        PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                slug: PropTypes.string.isRequired
            })
        )
    ]),
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    photoAuthor: PropTypes.string,
    imageUpdatedAt: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    categoryColor: PropTypes.string,
    columnist: PropTypes.node,
    authorSlug: PropTypes.string,
    inPictureAdConfig: PropTypes.shape({
        id: PropTypes.string,
        size: PropTypes.string,
        targeting: PropTypes.shape({
            pos: PropTypes.string
        })
    }),
    showAuthorInitials: PropTypes.bool
}

GalleryHead.defaultProps = {
    image: undefined,
    gallery: null,
    title: undefined,
    subtitle: undefined,
    lead: undefined,
    author: undefined,
    authors: [],
    date: undefined,
    photoAuthor: undefined,
    imageUpdatedAt: undefined,
    categoryColor: undefined,
    columnist: undefined,
    authorSlug: undefined,
    inPictureAdConfig: undefined,
    showAuthorInitials: false
}

export default withErrorBoundary(GalleryHead, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Head]: ', error, componentStack)
    }
})
