import { toRem } from '../../helpers'

const style = ({ theme, open, categoryColor }) => ({
    display: 'none',
    position: 'fixed',
    zIndex: theme.zIndex.modal,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    ...(open && {
        display: 'block'
    }),

    // Modal Inner Stuff:
    '.modal': {
        '&__backdrop': {
            zIndex: 10,
            position: 'fixed',
            right: 0,
            bottom: 0,
            top: 0,
            left: 0,
            backgroundColor: theme.colors.black
        },
        '&__inner': {
            position: 'relative',
            zIndex: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            minHeight: '100vh'
        }
    },

    // Gallery Stuff:
    '.gallery': {
        // Gallery Header Stuff:
        '&__header': {
            display: 'flex',
            flex: '0 0 auto',
            justifyContent: 'space-between',
            width: '100%',
            background: '#000',
            color: theme.colors.white,
            padding: '8px',

            [theme.breakpoints.up('md')]: {
                padding: '20px'
            }
        },

        '&__logo': {
            display: 'inline-block',
            height: '25px',
            flex: 1,

            [theme.breakpoints.up('md')]: {
                height: '30px'
            },

            '&-img': {
                width: 'auto',
                height: '100%',
                marginBottom: '10px',

                [theme.breakpoints.up('md')]: {
                    fontSize: '2rem',
                    marginBottom: '5px'
                }
            },

            '.colorme': {
                fill: theme.colors.brand
            },

            '.colortxt': {
                fill: theme.colors.white
            }
        },

        '&__close, &__header_share': {
            flex: '0 0 40px',
            padding: 0,
            border: 0,
            backgroundColor: 'transparent',
            color: theme.colors.white,
            fontSize: '24px',
            cursor: 'pointer',
            lineHeight: 0,
            textAlign: 'right',

            [theme.breakpoints.up('md')]: {
                lineHeight: 1,
                textAlign: 'center'
            }
        },

        '&__close': {
            marginTop: '2px',

            '> span > svg': {
                height: '16px',

                [theme.breakpoints.up('md')]: {
                    height: '24px'
                },

                path: {
                    fill: theme.colors.white
                }
            }
        },

        '&__header_share': {
            position: 'relative',
            fontSize: '24px',

            [theme.breakpoints.up('md')]: {
                display: 'none'
            },

            '> span > svg': {
                height: '24px',
                marginTop: '5px'
            },

            '> ul': {
                position: 'absolute',
                right: '-10px',
                lineHeight: 1
            },

            '> ul.is_floating': {
                position: 'absolute',
                top: '40px',
                right: 0,
                padding: '10px 10px 4px',
                lineHeight: 1,
                backgroundColor: theme.colors.grey900,
                border: '1px solid rgba(255, 255, 255, 0.3)',
                borderRadius: '4px',
                boxShadow: '0 0 6px #000'
            }
        },

        // Gallery Swiper Stuff:
        '&__main': {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            color: theme.colors.white,

            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                alignItems: 'stretch',
                justifyContent: 'stretch'
            }
        },

        '&__share': {
            display: 'none',

            [theme.breakpoints.up('md')]: {
                flex: '0 0 100px',
                order: 0,
                display: 'block'
            },

            ul: {
                margin: '8px 0 0'
            },

            li: {
                textAlign: 'center',

                [theme.breakpoints.up('md')]: {
                    display: 'block'
                }
            }
        },

        '&__swiper': {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            maxWidth: '100vw',
            padding: '10px',
            background: theme.colors.grey900,

            [theme.breakpoints.up('md')]: {
                maxWidth: 'calc(100vw - 440px)'
            },

            '.title': {
                marginBottom: '15px',
                color: theme.colors.white,
                fontSize: '1.5rem',

                [theme.breakpoints.up('md')]: {
                    fontSize: '2rem'
                }
            },

            '.title_subtitle': {
                color: categoryColor
            }
        },

        '&__marketing': {
            display: 'block',
            padding: '0',
            textAlign: 'center',

            '> div': {
                display: 'inline-block',
                maxWidth: '350px',
                maxHeight: '100px',
                textAlign: 'center',
                marginBottom: 0
            },

            [theme.breakpoints.up('md')]: {
                flex: '0 0 340px',
                display: 'block',
                padding: '20px 0 0',
                textAlign: 'center',

                '> div': {
                    display: 'inline-block',
                    width: '300px',
                    height: '600px'
                }
            }
        }
    },

    // Swiper Stuff:
    '.swiper': {
        position: 'absolute',
        top: 0,
        right: '-10px',
        bottom: 0,
        left: '-10px',
        paddingBottom: '30px',

        [theme.breakpoints.up('md')]: {
            // position: 'relative'
        },

        '&__container': {
            flex: 1,
            position: 'relative'
        },

        '&-slide': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            '.gallery__slide > div, .gallery__image': {
                // we don't need this <div> and <picture> wrapper
                display: 'contents'
            },

            '.gallery__slide': {
                position: 'relative',
                top: 'auto',
                right: 'auto',
                bottom: 'auto',
                left: 'auto',
                display: 'inline-block',
                width: 'auto',
                height: '100%',

                '&.is_landscape': {
                    height: 'auto',
                    width: '100%'
                },

                '&_source, &_description': {
                    position: 'absolute',
                    inset: '0 0 auto auto',

                    overflow: 'hidden',
                    display: 'inline-block',
                    padding: '5px 10px',
                    backgroundColor: 'rgba(8, 8, 8, .7)',
                    color: theme.colors.white,
                    fontSize: toRem(11),

                    i: {
                        fontStyle: 'normal'
                    }
                },

                '&_description': {
                    inset: 'auto auto 0 0',
                    textWrap: 'pretty',
                    maxHeight: '46px',
                    overflow: 'hidden',
                    transition: 'max-height 0.3s ease, padding 0.3s ease',
                    fontSize: toRem(14),
                    lineHeight: 1.4,

                    '&.is_open': {
                        maxHeight: '500px',
                        paddingTop: '10px',
                        paddingBottom: '25px'
                    },

                    '&.has_hiddenLines': {
                        cursor: 'pointer'
                    }
                },

                '.show_more': {
                    display: 'none',
                    position: 'absolute',
                    bottom: '-3px',
                    right: '0',
                    marginTop: '8px',
                    border: 'none',
                    color: '#069eff',
                    background:
                        'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 1) 100%)',
                    cursor: 'pointer',
                    padding: '0 10px 0 25px',
                    fontSize: '1em'
                },

                '.has_hiddenLines .show_more': {
                    display: 'block'
                },
                '&_description.is_open .open_msg': { display: 'none' },
                '&_description:not(.is_open) .close_msg': { display: 'none' }
            },

            img: {
                position: 'static',
                height: '100%',
                maxWidth: '100%',
                objectFit: 'contain',
                objectPosition: 'center'
            },
            span: {
                position: 'absolute',
                bottom: '10px',
                right: '10px',
                padding: '5px 10px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }
        },

        '[data-final-aspect="is_landscape"] .gallery__slide': {
            height: 'auto',
            width: '100%'
        },

        '&-button-next, &-button-prev': {
            '--swiper-navigation-size': '72px',

            right: 0,
            left: 'auto',
            width: '36px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',

            '&::before, &::after': {
                fontSize: '1.5rem',
                color: theme.colors.white
            }
        },

        '&:has(.gallery__slide_description.is_open)': {
            '.swiper-button-next, .swiper-button-prev': {
                display: 'none'
            }
        },

        '&-button-prev': {
            right: 'auto',
            left: 0
        },

        '&-pagination': {
            fontFamily: 'proxima-nova,ArialToProximaNova,sans-serif',
            fontSize: '1rem',
            fontWeight: 700,
            textShadow: '-1px 0 2px #080808, 0 1px 2px #080808, 1px 0 2px #080808, 0 -1px 2px #080808',

            '&-current': {
                color: categoryColor
            }
        }
    }
})

export default style
